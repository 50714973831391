.b-timer {
	position        : relative;
	display         : flex;
	flex-flow       : row nowrap;
	justify-content : space-between;
	height          : 96px;
	margin-bottom   : 25px;
	@media only screen and (max-width : 668px) {
		height : 60px;
	}
}

.b-timer__item {
	width              : 99px;
	height             : 96px;
	border             : 1px solid #AA7CCE;
	text-align         : center;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	color              : #F55399;
	font-size          : 60px;
	line-height        : 1;
	position           : relative;
	z-index            : 5;
	padding-top        : 6px;
	@media only screen and (max-width : 668px) {
		width       : 60px;
		height      : 60px;
		font-size   : 26px;
		padding-top : 8px;
	}
	&:after {
		content    : ' ';
		top        : 50%;
		left       : 100%;
		width      : 53px;
		background : #AA7CCE;
		height     : 1px;
		position   : absolute;
		@media only screen and (max-width : 668px) {
			width : 12px;
		}
	}
	&:last-child {
		&:after {
			display : none;
		}
	}
	span {
		font-size : 12px;
		color     : #FFF;
		display   : block;
	}
}
