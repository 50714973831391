@font-face {
	font-family : 'b-raleway';
	src         : url('../fonts/raleway-400-normal.woff2') format('woff2'),
	url('../fonts/raleway-400-normal.woff') format('woff'),
	url('../fonts/raleway-400-normal.ttf') format('truetype');
	font-weight : 400;
	font-style  : normal;
}

@font-face {
	font-family : 'b-raleway';
	src         : url('../fonts/raleway-500-normal.woff2') format('woff2'),
	url('../fonts/raleway-500-normal.woff') format('woff'),
	url('../fonts/raleway-500-normal.ttf') format('truetype');
	font-weight : 500;
	font-style  : normal;
}

@font-face {
	font-family : 'b-raleway';
	src         : url('../fonts/raleway-600-normal.woff2') format('woff2'),
	url('../fonts/raleway-600-normal.woff') format('woff'),
	url('../fonts/raleway-600-normal.ttf') format('truetype');
	font-weight : 700;
	font-style  : normal;
}