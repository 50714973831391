@charset "utf-8";

/*Larger devices (small laptops):*/
@media only screen and (max-width : 1200px) {
	.container {
		width : 900px;
	}
	h1#logo a {
		width  : 215px;
		height : 49px;
	}
	#header {
		padding       : 10px 0;
		margin-bottom : 15px;
		.container {
			&:before {
				bottom : -10px;
			}
		}
	}

	.tile-2-contact,
	.tile-full-auto {
		width : 880px;
	}
	.tile-4-roadmap,
	.tile-4-about,
	.tile-1-about,
	.tile-1-contact {
		width : 430px;
	}
	.tile-4-roadmap {
		height : 250px;
	}
	.tile-4-about {
		height : 230px;
	}
	.tile-1-roadmap {
		width  : 280px;
		height : 200px;
	}
	.tile-1-team {
		width  : 280px;
		height : 400px;
	}
	.tile-3-roadmap {
		width : 430px;
	}
	.b-team__title {
		font-size : 24px;
	}
	.element-blank {
		display : none;
	}
}

/*Medium devices (landscape tablets):*/
@media only screen and (max-width : 992px) {
	.client {
		width : 33.3333333%;
	}
	nav .social-list {
		padding-bottom : 0;
	}
	.container {
		width : 600px;
	}
	#menu-button {
		display : table;
	}
	nav {
		position   : absolute;
		width      : 100%;
		display    : block;
		z-index    : 900;
		background : #423885;
		box-shadow : 0 2px 15px 0 rgba(0, 0, 0, 0.1);
		top        : -500px;
		transition : top 0.3s ease-in-out;
	}
	.pushed-left nav {
		top        : -34px;
		transition : top 0.3s ease-in-out;
	}
	#main-nav ul {
		float   : none;
		padding : 20px 0;
	}
	#main-nav ul li {
		float      : none;
		text-align : center;
	}
	#main-nav ul li a {
		padding : 4px 0 5px 0px;
	}
	#main-nav ul li ul {
		padding : 4px 0 5px;
	}
	#container {
		padding-bottom : 20px;
	}
	.tile-1-roadmap,
	.tile-4-roadmap,
	.tile-3-roadmap,
	.tile-1-team,
	.tile-4-about,
	.tile-2-contact,
	.tile-full-auto {
		width : 580px;
	}
	.tile-4-about {
		height : auto;
	}
	.tile-1-about,
	.tile-1-contact {
		width : 280px;
	}

	.tile-1-roadmap,
	.tile-4-roadmap,
	.tile-3-roadmap {
		height : auto;
	}
}

/*Small devices (portrait tablets):*/
@media only screen and (max-width : 668px) {
	.container {
		width : 320px;
	}
	.client {
		width : 50%;
	}
	.tile-2,
	.tile-4,
	.tile-4-about,
	.tile-1-about,
	.tile-1-contact,
	.tile-2-contact,
	.tile-full-auto {
		width  : 280px;
		height : auto;
	}
	.element.is-timer {

	}
	.element, .image-overlay {
		width : 300px !important;
	}
	.element__pie-image {
		position   : static;
		margin-top : 25px;
		text-align : center;
	}
	.element__title {
		font-size : 22px;
	}
	h1#logo a {
		width  : 135px;
		height : 31px;
	}
}

/*Extra small devices (phones):*/
@media only screen and (max-width : 480px) {
	footer p {
		text-align : center !important;
		float      : none !important;
	}
	header .social-list {
		right : 19px;
	}
	header .social-list li {
		padding : 0 4px;
	}
	p.small {
		padding-bottom : 0;
	}
	h3.panel-title {
		padding : 25px 0px !important;
	}
	.panel-heading::after, .panel-heading::before {
		right : 0;
	}
	.panel-body {
		padding : 5px 0px 20px !important
	}
	.more-margin {
		margin-top : 10px;
	}
	.header {
		padding-bottom : 0px;
	}
}
