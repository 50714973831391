input, textarea, select {
	border             : 0;
	-webkit-transition : all 0.2s ease 0s;
	-moz-transition    : all 0.2s ease 0s;
	-o-transition      : all 0.2s ease 0s;
	transition         : all 0.2s ease 0s;
	float              : none;
	display            : block;
	margin             : 0;
	-webkit-appearance : none;
}

textarea {
	resize : none;
}

#message {
	margin     : 0;
	padding    : 0px;
	z-index    : 200;
	height     : auto;
	text-align : center !important
}

.error_message, #success_page {
	font-weight    : 400;
	font-size      : 14px;
	background     : #FCF8E3;
	padding        : 13px 20px;
	text-transform : none;
	margin-top     : 5px;
}

.error_message {
	background : #F9ECEC;
	color      : #D94845;
}

#success_page {
	background : #DFF0D8;
	color      : #468847;
}

.loader {
	display : none;
}

.form-part {
	padding    : 0;
	margin-top : 4px;
}

.form-part > textarea, .form-part > select, .form-part > input:not([type="submit"]):not([type="image"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]) {
	display       : block;
	width         : 100%;
	padding       : 20px 0;
	border        : 0;
	color         : #FFF;
	border-bottom : 1px solid #C94C8A;
	text-align    : left;
	background    : none;
}

.form-part > textarea {
	height : 100px;
	resize : none;
}

.form-part > .input-wrapper {
	position : relative;
}

.form-part input:focus, .form-part textarea:focus {
	background : none !important;
}

.form-part {
	#submit {
		border      : 0;
		display     : inline-block;
		margin-top  : 25px;
		font-weight : 400;
		color       : #FFF;
		cursor      : pointer;
		background  : transparent;
		span {
			text-decoration : underline;
			color           : #FFF;
		}
		&:hover {
			span {
				text-decoration : none;
			}
		}
	}
}
